import { ReactElement } from 'react';
import { IComponentTextOverThreeBlocksFields, IFragmentImageWithCopy } from 'types/contentful';

import ImageBlock from '../UI/ImageBlock';
import RichText from '../UI/Richtext';

export default function TextOverThreeBlocks({
  heading,
  copy,
  blocks,
}: IComponentTextOverThreeBlocksFields): ReactElement {
  return (
    <div className="px-4 lg:px-16 xl:px-32 pt-6 pb-1">
      <div className="pl-0 lg:pl-6">
        <h2 className="text-2xl lg:text-4xl  mb-2">{heading}</h2>
        {copy && <RichText document={copy} classNames="text-sm lg:text-lg" />}
      </div>
      <div className="flex flex-col lg:flex-row flex-wrap mt-4 lg:mt-8">
        {blocks.map((block: IFragmentImageWithCopy, i: number) => (
          <ImageBlock key={i} {...block.fields} />
        ))}
      </div>
    </div>
  );
}
