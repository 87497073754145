import { ReactElement } from 'react';
import { IFragmentImageWithCopyFields } from 'types/contentful';

import Image from './Image';
import RichText from './Richtext';

export default function ImageBlock({
  image,
  heading,
  copy,
}: IFragmentImageWithCopyFields): ReactElement {
  return (
    <div className="w-full lg:w-1/3 border-transparent mb-2 lg:mb-0 lg:border-8">
      <div>
        <Image image={image} query="w=760" classNames="w-full h-full" />
      </div>
      <div className="py-5 lg:p-5">
        {heading && <h3 className="text-xl lg:text-2xl ">{heading}</h3>}
        {copy && <RichText document={copy} classNames="mt-4 lg:mt-6 text-sm lg:text-lg" />}
      </div>
    </div>
  );
}
